<template>
  <div>
    <b-modal :no-close-on-backdrop="true"
      id="add-doc-modal"
      ref="doc-upload-modal"
      centered
      title="Upload Document"
      ok-title="Submit"
      @hidden="resetAddDocModal"
      @ok="documentUpload"
    >
      <br />
      <b-form-select class="form-group" v-model="document_type">
        <b-form-select-option
          v-for="(item, index) in $store.getters['home/getDocumentTypes']"
          :key="'doc_types_' + index"
          :value="item.doc_id"
        >
          {{ item.doc_name }}
        </b-form-select-option>
      </b-form-select>
      <br />
      <b-form-input
        v-if="document_type === 30"
        v-model="document_extra_label"
        placeholder="Enter Document Name"
      />
      <br />
      <br />
      <b-form-file
        class="form-group"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        v-model="document_file"
      ></b-form-file>
      <br />
    </b-modal>

    <b-modal :no-close-on-backdrop="true"
      id="remove-doc-modal"
      ref="doc-remove-modal"
      centered
      title="Remove Document"
      ok-title="Remove"
      @hidden="resetRemoveDocId"
      @ok="removeDoc"
    >
      {{ doc_to_remove ? doc_to_remove.doc_name : "" }}
    </b-modal>

    <b-card>
      <b-tabs v-model="currentTab">
        <b-tab title="Upload Documents">
          <div class="custom-search d-flex justify-content-end">
            <div class="demo-inline-spacing">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.add-doc-modal
                variant="outline-primary"
              >
                Add document
              </b-button>
            </div>
          </div>
          <br />

          <vue-good-table
            :columns="columns"
            :rows="document_list"
            :rtl="direction"
          >
            <template slot="table-row" slot-scope="props">
              <span
                v-if="props.column.field === 'doc_name'"
                class="text-wrap text-uppercase"
              >
                {{ props.row.doc_name }}
                {{
                  props.row.doc_id === 30 && props.row.extra_label
                    ? ` - ${props.row.extra_label}`
                    : ""
                }}
              </span>
              <span v-if="props.column.field === 'open'" class="text-nowrap">
                <div
                  @click="(e) => openDoc(e, props)"
                  class="text-center"
                  role="button"
                >
                  <b-avatar variant="light-success" size="30">
                    <feather-icon
                      icon="EyeIcon"
                      size="15"
                      class="text-success"
                    />
                  </b-avatar>
                </div>
              </span>
              <span
                v-if="props.column.field === 'remove_doc'"
                class="text-nowrap"
              >
                <div
                  v-if="props.row.doc_approved !== 'approved'"
                  @click="setDocIdToRemove(props.row)"
                  class="text-center"
                  role="button"
                >
                  <b-avatar variant="light-danger" size="30">
                    <feather-icon
                      icon="TrashIcon"
                      size="15"
                      class="text-danger"
                    />
                  </b-avatar>
                </div>
                <div v-else></div>
              </span>
              <span
                v-if="props.column.field === 'doc_approved'"
                class="text-nowrap text-capitalize"
              >
                <b-badge variant="light-danger">
                  {{ props.row.doc_approved }}
                </b-badge>
              </span>
              <span
                v-else-if="props.column.field === 'created_at'"
                class="text-nowrap"
              >
                {{ getFormattedDate(props.row.created_at) }}
              </span>
              <span
                v-else-if="props.column.field === 'comments_mentioned'"
                class="text-nowrap"
              >
                {{ props.row.extra_text }}
              </span>
            </template>
          </vue-good-table>
        </b-tab>

        <b-tab title="Application Checklist">
          <application-checklist @set-doc-id="(id) => setDocumentType(id)" />
        </b-tab>

        <b-tab title="Sample Documents">
          <sample-documents />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BFormSelectOption,
  BCard,
  BTab,
  BTabs,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import StudentServices from "@/apiServices/StudentServices";
import Ripple from "vue-ripple-directive";
import { BASEURL, FILESURL } from "@/config/index";
import axios from "axios";
import { TokenService } from "@/apiServices/storageService";
import helpers from "@/libs/helpers";
import SampleDocuments from "./SampleDocuments.vue";
import ApplicationChecklist from "./ApplicationCheckList.vue";

export default {
  components: {
    VueGoodTable,
    BFormFile,
    BModal,
    BButton,
    VBModal,
    BAlert,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormSelectOption,
    BCard,
    BTab,
    BTabs,
    ApplicationChecklist,
    SampleDocuments,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      doc_to_remove: null,
      pageLength: 10,
      currentTab: 0,
      dir: false,
      columns: [
        {
          label: "Type of document",
          field: "doc_name",
        },
        {
          label: "Preview",
          field: "open",
        },
        {
          label: "Approval Status",
          field: "doc_approved",
        },
        {
          label: "Comments Mentioned",
          field: "comments_mentioned",
        },
        {
          label: "Uploaded Date",
          field: "created_at",
        },
        {
          label: "Delete",
          field: "remove_doc",
        },
      ],
      searchTerm: "",
      document_list: [],
      document_type: null,
      document_file: null,
      document_extra_label: null,
    };
  },
  computed: {
    // studentDocType() {
    //   let StudentDocTypes = [];
    //   store.getters["home/getDocumentTypes"];

    // },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    getFormattedDate: helpers.getDateTimeFormatted,
    resetAddDocModal() {
      this.document_file = null;
      this.document_type = null;
    },
    resetRemoveDocId() {
      this.doc_to_remove = null;
    },
    setDocIdToRemove(doc) {
      this.$refs["doc-remove-modal"].show();
      this.doc_to_remove = doc;
    },
    openDoc(e, prop) {
      const url = FILESURL + prop.row.doc_url;
      window.open(url);
    },
    removeDoc() {
      const sd_mapper_id = this.doc_to_remove.sd_mapper_id;
      StudentServices.RemoveDocument({ sd_mapper_id })
        .then((response) => {
          if (response.data.status) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Document Removed",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getAllDocuments();
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Document Remove Failed",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error removing document ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Server Error",
              icon: "EditIcon",
              variant: "failure",
            },
          });
          this.$nextTick(() => {
            this.$refs["doc-remove-modal"].toggle("#toggle-btn");
          });
        });
      this.getAllDocuments();
    },
    setDocumentType(id) {
      this.document_type = id;
    },
    async documentUpload(e) {
      e.preventDefault();

      if (!this.document_file) {
        helpers.showErrorToast(this, "Please Upload Document");
        return;
      }

      const format = /[^\x00-\x7F]/;
      if (format.test(this.document_file.name)) {
        helpers.showErrorToast(
          this,
          "Filename cannot contain special characters"
        );
        return;
      }

      if (this.document_file.size > 10 * 1024 * 1024) {
        helpers.showErrorToast(this, "Filesize cannot be greater than 10MB");
        return;
      }

      if (!this.document_type) {
        helpers.showErrorToast(this, "Select Document Type");
        return;
      }

      if (this.document_type === 30 && !this.document_extra_label) {
        helpers.showErrorToast(this, "Enter Document Name");
        return;
      }

      const document_extra_label = this.document_extra_label;
      this.document_extra_label = null;

      try {
        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("doc", this.document_file);

        const response = await axios.post(
          `${BASEURL}/student/document/upload/${this.document_type}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
            params: {
              document_extra_label,
            },
          }
        );

        if (response.data.status) {
          this.getAllDocuments();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$nextTick(() => {
            this.$refs["doc-upload-modal"].toggle("#toggle-btn");
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Uploaded Failed",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error uploading file ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      this.currentTab = 0;
    },
    getAllDocuments() {
      StudentServices.getAllDocuments()
        .then((response) => {
          if (response.data.status) {
            this.document_list = response.data.data;
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title:
                  response.data.message || "Server Error Getting Documents",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        })
        .catch((err) => {
          console.log("Error getting documents ", err);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Server Error Getting Documents",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        });
    },
  },
  beforeMount() {
    this.getAllDocuments();
    this.$store.dispatch("home/onGetDocumentTypes");
  },
};
</script>


<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

